import React, {useEffect, useState} from "react";
import Loading from "../components/Loading";
import {Outlet} from "react-router-dom";
import {AdminContext} from '../contex/AdminContext';
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function AuthProvider()
{
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const authErrorHandler = () => {
    sessionStorage.removeItem('access_token');
    localStorage.removeItem('access_token');
    navigate('/Login');
  }

  useEffect(() => {
    let token  = sessionStorage.getItem('access_token');
    if(localStorage.getItem('access_token')){
      token = localStorage.getItem('access_token')
    }

    if(!token){
      navigate('/Login');
      return;
    }

    const AuthAPI = axios.create({
      baseURL: process.env.REACT_APP_API_URL + '/',
      responseType: "json",
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' :  token
      },
    })

    setUser({AuthAPI, authErrorHandler});

  }, [])

  return (
    <>
      <AdminContext.Provider value={{...user}}>
        { user ? <Outlet /> : <Loading /> }
      </AdminContext.Provider>
    </>
  )
}