import React from "react";
import {Outlet} from "react-router-dom";

export default function MainLayout()
{
  return (
    <>
      <div className="app-wrapper" id="app-wrapper">

        <div className="main-layout">
          <Outlet />
        </div>
      </div>
    </>
  )
}