import React from 'react';
import {ClimbingBoxLoader} from "react-spinners";

const Loading = () => {
  return (
    <>
      <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
        <div className="d-flex align-items-center flex-column px-4">
          <ClimbingBoxLoader color={'#b7b7b7'} loading={true} />
        </div>
        <div className="font-size-xl text-center pt-3 text-light">
          Podaci se učitavaju...
          <span className="font-size-lg d-block text-muted">
              Molimo pričekajte
            </span>
        </div>
      </div>
    </>
  )
}

export default Loading;
