import React, {useEffect, useState} from "react";
import clsx from "clsx";

export default function GoalScreen()
{
   const [active, setActive] = useState(false);

   useEffect(() => {
      const timer = setTimeout(() => {
         setActive(true);
      }, 50);

      return () => clearTimeout(timer);
   }, [])

   return (
      <div className={clsx("goal-wrapper", {active})}>
         <div className="goal-bg"></div>
         <div className="goal-text"></div>
      </div>
   )
}