import { BrowserRouter } from 'react-router-dom';
import './App.scss';
import Routes from './Routes';


function App() {
  return (
    <BrowserRouter basename="/">
      <Routes />
    </BrowserRouter>
  );
}

export default App;
