import React, {useContext, useState} from "react";
import {AdminContext} from "../../contex/AdminContext";
import {Alert, Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

export default function GoalModal({modalData, setModalData, callbackHandler})
{
  const {AuthAPI, authErrorHandler} = useContext(AdminContext);
  const [formData, setFormData] = useState({name: "", time: modalData.time, team: ""})
  const [hasError, setHasError] = useState(false);

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setFormData({...formData, [name]: value});
  }

  const closeModal = () => {
    setModalData({show: false, time: ''})
  }

  const submitHandler = () => {
    if(formData.name.length === 0 || formData.time.length === 0 || formData.team.length === 0){
      setHasError('Popunite sva polja')
      return;
    }

    AuthAPI.post('/score', formData).then((res) => {
      callbackHandler(res.data);
    }).catch((error) => {
      setHasError("Greška");
      console.log('error', error.response.data);
      if(error.response && error.response.status === 401){
        authErrorHandler();
      }
    })
  }

  return (
    <>
      <Modal isOpen={true} size="md">
        <ModalHeader toggle={() => {closeModal()}}>
          Novi gol
        </ModalHeader>
        <ModalBody>
          {hasError && (
            <Alert color="danger">{ hasError }</Alert>
          )}
          <FormGroup>
            <Label>Igrač</Label>
            <Input type="text" name="name" value={formData.name} onChange={handleChange} />
          </FormGroup>
          <FormGroup>
            <Label>Vrijeme</Label>
            <Input type="text" name="time" value={formData.time} onChange={handleChange} />
          </FormGroup>

          <Label>Ekipa</Label>
          <div className="d-flex">
            <FormGroup check className="flex-grow-1">
              <Label check>
                <Input type="radio" name="team" value="1" checked={formData.team === "1"} onChange={handleChange} /> Domaći
              </Label>
            </FormGroup>
            <FormGroup check className="flex-grow-1">
              <Label check>
                <Input type="radio" name="team" value="2" checked={formData.team === "2"} onChange={handleChange} /> Gosti
              </Label>
            </FormGroup>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={closeModal}>Odustani</Button>
          <Button color="primary" onClick={submitHandler}>Potvrdi</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}