import React, {useEffect, useState} from "react";
import axios from "axios";
import {Outlet} from "react-router-dom";
import Loading from "../components/Loading";
import {MainContext} from "../contex/MainContext";

export default function MainProvider()
{
  const [data, setData] = useState(null);

  useEffect(() => {

    const API = axios.create({
      baseURL: process.env.REACT_APP_API_URL + '/',
      responseType: "json",
      headers: {
        'Content-Type': 'application/json',
      },
    })

    setData({API});

  }, [])

  return (
    <>
      <MainContext.Provider value={{...data}}>
        { data ? <Outlet /> : <Loading /> }
      </MainContext.Provider>
    </>
  )
}