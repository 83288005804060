import React from "react";
import {Outlet} from "react-router-dom";

export default function AdminLayout()
{

  return (
    <>
      <div className="app-wrapper" id="app-wrapper">

        <div className="content">
          <Outlet />
        </div>
      </div>
    </>
  )
}