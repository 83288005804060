import React, {useContext, useEffect, useState} from "react";
import {MainContext} from "../../contex/MainContext";
import Loading from "../../components/Loading";
import io from "socket.io-client";
import GoalScreen from "../../components/GoalScreen";

const socket = io(process.env.REACT_APP_API_URL,  { autoConnect: true });

export default function Semafor()
{
  const {API} = useContext(MainContext);
  const [settings, setSettings] = useState({});
  const [score, setScore] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [displayTime, setDisplayTime] = useState("00:00");
  const [displayExtraTime, setDisplayExtraTime] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const imagePrefix = process.env.REACT_APP_API_URL + '/images/'
  const [showScreen, setShowScreen] = useState("score");
  const [newScore, setNewScore] = useState("Manđukić 47");

  const formatLeadingzero = (time) => {
    time = time.toString();
    if(time.length < 2){
      time = "0" + time;
    }
    return time;
  }

  const handleDisplayTime = (time) => {
    let min = formatLeadingzero(parseInt(time/60));
    let seconds = formatLeadingzero(time % 60);

    return min + ":" + seconds;
  }

  const changeTime = () => {
    setCurrentTime((oldValue) => {
      const newTime = oldValue + 1;

      let borderTime = 0;
      if(settings.offset >= 0 && settings.offset < 45){
        borderTime = 45*60;
      }
      if(settings.offset >= 45 && settings.offset < 90){
        borderTime = 90*60;
      }

      if(newTime < borderTime){
        setDisplayTime(handleDisplayTime(newTime));
        setDisplayExtraTime(false);
      }
      else {
        setDisplayTime(handleDisplayTime(borderTime));
        setDisplayExtraTime(handleDisplayTime(newTime - borderTime));
      }

      return newTime
    })
  }

  const loadSettings = async () => {
    const response = await API.get('/settings');
    setSettings(response.data);
  }

  const loadScore = async () => {
    const response = await API.get('/score');
    setScore(response.data);
  }

  const loadData = async () => {
    await loadSettings();
    await loadScore();
    setIsLoading(false);
  }

  useEffect(() => {
    loadData();

    socket.on('start-game', () => {
      loadSettings();
    });

    socket.on('pause-game', () => {
      loadSettings();
    });

    socket.on('reload-game', () => {
      loadData();
    });

    socket.on('add-score', (name, time) => {
      loadScore();
      setShowScreen("goal")
      setNewScore(name+' '+time+'"')
      let timerPlayer = null;
      const timer = setTimeout(() => {
        setShowScreen("goal-player");
        timerPlayer = setTimeout(() => setShowScreen('score'), 10000)
      }, 3000);

      return () => {
        clearTimeout(timer)
        clearTimeout(timerPlayer)
      };
    });

    socket.on('remove-score', () => {
      loadScore();
    });

    return () => {
      socket.off('start-game');
      socket.off('pause-game');
      socket.off('reload-game');
      socket.off('add-score');
      socket.off('remove-score');
    }
  }, [])

  useEffect(() => {
    if(settings.startTime){
      const date = new Date(settings.currentTime);
      const startTime = new Date(settings.startTime);
      const offset = parseInt(settings.offset) * 60;

      const time = parseInt((date-startTime) / 1000);
      setCurrentTime(offset + time);

      const interval = setInterval(() => {
        changeTime();
      }, 1000);
      return () => clearInterval(interval);
    }
    else{
      const offset = parseInt(settings.offset) * 60;
      if(!isNaN(offset)){
        setDisplayTime(handleDisplayTime(offset))
      }
    }
  }, [settings])

  return (
    <>
      { isLoading ? <Loading /> : (
        <>
          <div className="title">{ settings.title }</div>
          <div className="time">
            { displayTime }
          </div>

          {displayExtraTime && (
            <div className="extra-time">
              { displayExtraTime }
            </div>
          )}

          <div className="subtitle">
            { settings.subtitle }
          </div>

          <div className="score-wrapper">
            <div className="teams-container">
              <div className="team-wrapper">
                <div className="team">
                  { settings.logoHome && <img src={imagePrefix + settings.logoHome} alt="logo" /> }
                </div>
              </div>
              <div className="team-wrapper">
                <div className="team">
                  { settings.logoGuest && <img src={imagePrefix + settings.logoGuest} alt="logo" /> }
                </div>
              </div>
            </div>

            { showScreen === "score" && (
               <section className="score-main">
                 <div className="score-players">
                   <ul className="home">
                     { score.goalScores.map((item) => {
                       if(item.team === "1"){
                         return <li key={item.id}>{ item.name } <span>{ item.time }"</span></li>
                       }
                     })}
                   </ul>
                 </div>
                 <div className="score">
                   { score.home }<span>:</span>{ score.guest }
                 </div>
                 <div className="score-players">
                   <ul className="guest">
                     { score.goalScores.map((item) => {
                       if(item.team === "2"){
                         return <li key={item.id}>{ item.name } <span>{ item.time }"</span></li>
                       }
                     })}
                   </ul>
                 </div>
               </section>
            )}
          </div>

          {showScreen === "goal" && <GoalScreen />}

          {showScreen === "goal-player" && (
             <div className="goal-player-wrapper">
               <div className="goal-title">GOOL!</div>
               <div className="goal-player">{ newScore }</div>
             </div>
          )}

        </>
      )}
    </>
  )
}