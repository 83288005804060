import React, {useContext, useEffect, useState} from "react";
import Loading from "../../components/Loading";
import {
   Button,
   Card,
   CardBody,
   Col,
   FormGroup,
   Input,
   Label,
   Nav,
   Navbar,
   NavItem,
   NavLink,
   Row
} from "reactstrap";
import {AdminContext} from "../../contex/AdminContext";
import ImageModal from "../../components/ImageModal";
import {useNavigate} from "react-router-dom";

export default function Settings()
{
   const {AuthAPI, authErrorHandler} = useContext(AdminContext);
   const [isLoading, setIsLoading] = useState(true);
   const [formData, setFormData] = useState({});
   const [imageModalData, setImageModalData] = useState({show: false, team: null})
   const imagePrefix = process.env.REACT_APP_API_URL + '/images/'
   const navigate = useNavigate();

   const handleChange = (e) => {
      const value = e.target.value;
      const name = e.target.name;

      setFormData({...formData, [name]: value})
   }

   const handleTeamLogo = (teamID) => {
      setImageModalData({show: true, team: teamID})
   }

   const callbackHandler = (url) => {
      let name = "logoGuest";
      if(imageModalData.team === 1){
         name = "logoHome"
      }

      setFormData({...formData, [name]: url});
      setImageModalData({show: false, team: null})
   }

   const submitHandler = () => {
      let offset = parseInt(formData.offset);
      if(isNaN(offset)){
         offset = 0;
      }
      let data = {...formData, offset}

      AuthAPI.post('/settings', data).then((res) => {
         navigate('/');
      }).catch((error) => {
         console.log('error', error.response.data);
         if(error.response && error.response.status === 401){
            authErrorHandler();
         }
      })
   }

   const loadData = async () => {
      const response = await AuthAPI.get('/settings');
      setFormData({...response.data, offset: 0});
      setIsLoading(false);
   }

   useEffect(() => {
      loadData();
   }, [])

   return (
      <>
         { isLoading ? <Loading /> : (
            <div className="settings-page">
               <Navbar>
                  <Nav className="me-auto" navbar>
                     <NavItem>
                        <NavLink href="/">Semafor</NavLink>
                     </NavItem>
                  </Nav>

                  <Button color="primary" size="sm" onClick={submitHandler}>
                     Spremi
                  </Button>
               </Navbar>

               <Card>
                 <CardBody>
                    <FormGroup>
                       <Label>Naslov</Label>
                       <Input type="text" name="title" value={formData.title} onChange={handleChange} />
                    </FormGroup>
                    <FormGroup>
                       <Label>Podnaslov</Label>
                       <Input type="text" name="subtitle" value={formData.subtitle} onChange={handleChange} />
                    </FormGroup>
                    <FormGroup>
                       <Label>Početno vrijeme (min)</Label>
                       <Input type="number" name="offset" value={formData.offset} onChange={handleChange} />
                    </FormGroup>

                    <Row>
                       <Col xs="6">
                          <div className="text-center">
                             <h3>Domaći</h3>
                             <Button className="logo-container" onClick={() => handleTeamLogo(1)}>
                                <div className="logo">
                                   { formData.logoHome && <img src={imagePrefix + formData.logoHome} alt="logo" /> }
                                </div>
                                <div className="logo-overlay" />
                             </Button>
                          </div>
                       </Col>
                       <Col xs="6">
                          <div className="text-center">
                             <h3>Gosti</h3>
                             <Button className="logo-container" onClick={() => handleTeamLogo(2)}>
                                <div className="logo">
                                   { formData.logoGuest && <img src={imagePrefix + formData.logoGuest} alt="logo" /> }
                                </div>
                                <div className="logo-overlay" />
                             </Button>
                          </div>
                       </Col>
                    </Row>
                 </CardBody>
               </Card>

               <ImageModal modalData={imageModalData} setModalData={setImageModalData} callbackHandler={callbackHandler} />
            </div>
         )}
      </>
   )
}