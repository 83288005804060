import React, {useContext, useEffect, useState} from "react";
import {AdminContext} from "../../contex/AdminContext";
import Loading from "../../components/Loading";
import {Button, Container, Nav, Navbar, NavbarText, NavItem, NavLink} from "reactstrap";
import GoalModal from "../../components/GoalModal";
import clsx from "clsx";
import ConfirmModal from "../../components/ConfirmModal";

export default function HomePage()
{
  const {AuthAPI, authErrorHandler} = useContext(AdminContext);
  const [settings, setSettings] = useState({});
  const [score, setScore] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [displayTime, setDisplayTime] = useState("00:00");
  const [displayExtraTime, setDisplayExtraTime] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [showBtn, setShowBtn] = useState({pause: false, refresh: false})
  const [modalData, setModalData] = useState({show: false, time: ''});
  const [removeModalData, setRemoveModalData] = useState({show: false, itemID: null});

  const formatLeadingzero = (time) => {
    time = time.toString();
    if(time.length < 2){
      time = "0" + time;
    }
    return time;
  }

  const handleDisplayTime = (time) => {
    let min = formatLeadingzero(parseInt(time/60));
    let seconds = formatLeadingzero(time % 60);

    return min + ":" + seconds;
  }

  const changeTime = () => {
    setCurrentTime((oldValue) => {
      const newTime = oldValue + 1;

      let borderTime = 0;
      if(settings.offset >= 0 && settings.offset < 45){
        borderTime = 45*60;
      }
      if(settings.offset >= 45 && settings.offset < 90){
        borderTime = 90*60;
      }

      if(newTime < borderTime){
        setDisplayTime(handleDisplayTime(newTime));
        setShowBtn({refresh: true, pause: false})
      }
      else {
        setDisplayTime(handleDisplayTime(borderTime));
        setDisplayExtraTime(handleDisplayTime(newTime - borderTime));
        setShowBtn({refresh: false, pause: true})
      }

      return newTime
    })
  }

  const startHandler = () => {
    AuthAPI.post('/start').then((res) => {
      setSettings(res.data);
    }).catch((error) => {
      if(error.response && error.response.status === 401){
        authErrorHandler();
      }
    })
  }

  const pauseHandler = () => {
    AuthAPI.post('/pause').then((res) => {
      setSettings(res.data);

      setDisplayExtraTime(false);
      setShowBtn({refresh: false, pause: false})
    }).catch((error) => {
      if(error.response && error.response.status === 401){
        authErrorHandler();
      }
    })
  }

  const refreshHandler = () => {
    AuthAPI.post('/reload').then((res) => {
    }).catch((error) => {
      if(error.response && error.response.status === 401){
        authErrorHandler();
      }
    })
  }

  const goalHandler = () => {
    let time = currentTime;
    time = parseInt(time / 60) + 1;
    let displayTime = '';

    let borderTime = 0;
    if(settings.offset >= 0 && settings.offset < 45){
      borderTime = 45;
    }
    if(settings.offset >= 45 && settings.offset < 90){
      borderTime = 90;
    }

    if(time > borderTime){
      time = time - borderTime;
      displayTime = "45 +" + time;
    }
    else{
      displayTime = formatLeadingzero(time);
    }

    setModalData({show: true, time: displayTime})
  }

  const goalCallbackHandler = (data) => {
    setScore(data);
    setModalData({show: false, time: ''});
  }

  const removeHandler = (itemID) => {
    setRemoveModalData({show: true, itemID});
  }

  const removeCallbackHandler = () => {
    AuthAPI.delete('/score/' + removeModalData.itemID).then((res) => {
      setScore(res.data);
      setRemoveModalData({show: false, itemID: null});
    }).catch((error) => {
      if(error.response && error.response.status === 401){
        authErrorHandler();
      }
    })
  }

  useEffect(() => {
    if(settings.startTime){
      const date = new Date();
      const startTime = new Date(settings.startTime);
      const offset = parseInt(settings.offset) * 60;

      const time = parseInt((date-startTime) / 1000);
      setCurrentTime(offset + time);

      const interval = setInterval(() => {
        changeTime();
      }, 1000);
      return () => clearInterval(interval);
    }
    else{
      const offset = parseInt(settings.offset) * 60;
      if(!isNaN(offset)){
        setDisplayTime(handleDisplayTime(offset))
      }
    }
  }, [settings])

  const loadData = async () => {
    const responseSettings = await AuthAPI.get('/settings');
    const responseScore = await AuthAPI.get('/score');

    setSettings(responseSettings.data);
    setScore(responseScore.data);
    setIsLoading(false);
  }

  useEffect(() => {
    loadData();
  }, [])

  return (
    <>
      { isLoading ? <Loading/> : (
        <div className="home-page">
          <Navbar>
            <Nav className="me-auto" navbar>
              <NavItem>
                <NavLink href="/settings">Postavke</NavLink>
              </NavItem>
            </Nav>

            { settings.startTime === null && (
              <Button color="success" size="sm" onClick={startHandler}>
                Start
              </Button>
            )}

            { showBtn.refresh && (
              <Button color="light" size="sm" onClick={refreshHandler}>
                Osvježi
              </Button>
            )}

            { showBtn.pause && (
              <Button color="danger" size="sm" onClick={pauseHandler}>
                Stop
              </Button>
            )}
          </Navbar>

          <div className="time">
            { displayTime }
          </div>

          {displayExtraTime && (
            <div className="extra-time">
              { displayExtraTime }
            </div>
          )}

          <div className="score">
            { score.home } : { score.guest }
          </div>

          <div className="new-score">
            { settings.startTime && (
              <Button color="success" size="lg" onClick={goalHandler}>
                GOOL
              </Button>
            )}
          </div>

          <Container>
            <div className="goal-scores">
              { score.goalScores.map((item) => (
                <div className="item" key={item.id}>
                  <div className={clsx("name", {guest: item.team === "2"})}>
                    { item.name } { item.time }"
                  </div>
                  <div className="btn-container">
                    <Button  onClick={() => removeHandler(item.id)}>X</Button>
                  </div>
                </div>
              ))}
            </div>
          </Container>

          { modalData.show && <GoalModal modalData={modalData} setModalData={setModalData} callbackHandler={goalCallbackHandler} /> }

          { removeModalData.show && <ConfirmModal setModalData={setRemoveModalData} callbackHandler={removeCallbackHandler} /> }
        </div>
      )}
    </>
  )
}