import React, {useState} from "react";
import {Alert, Button, Card, CardBody, CardHeader, FormGroup, Input, Label} from "reactstrap";
import axios from 'axios';
import logo from "../../assets/images/nk_maksimir_logo.png"
import { useNavigate } from "react-router-dom";

export default function LoginPage()
{
  const [formData, setFormData] = useState({username: '', password: '', rememberMe: false});
  const [hasError, setHasError] = useState(false)
  const navigate = useNavigate();

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setFormData({...formData, [name]: value});
  }

  const submitHandler = () => {
    setHasError(false);
    if(formData.username.length === 0 || formData.password.length === 0){
      setHasError('Popunite sva polja');
      return;
    }

    axios.post(process.env.REACT_APP_API_URL+'/login', formData)
      .then(res => {
        sessionStorage.setItem('access_token', res.data.token);
        if(formData.rememberMe){
          localStorage.setItem('access_token', res.data.token);
        }

        navigate('/');

      }).catch(error => {
      if(error.response){
        console.log('error', error.response.data)
        if(error.response.status === 400){
          setHasError('Pogrešno korisničko ime ili lozinka');
        }
        else{
          setHasError('Serverska greška');
        }
      }
      else {
        setHasError('Greška u komunikaciji sa serverom');
      }
    });
  }


  return (
    <>
      <div className="login-page">
        <div className="logo-wrapper">
          <img src={logo} alt="logo nk maksimir" />
        </div>

        <Card>
          <CardHeader>Prijava</CardHeader>
          <CardBody>
            { hasError && (
              <Alert color="danger">
                { hasError }
              </Alert>
            )}
            <FormGroup>
              <Label>Korisničko ime</Label>
              <Input type="text" name="username" value={formData.username} onChange={handleChange} />
            </FormGroup>
            <FormGroup>
              <Label>Lozinka</Label>
              <Input type="password" name="password" value={formData.password} onChange={handleChange} />
            </FormGroup>

            <div className="btn-container text-center">
              <Button color="primary" onClick={submitHandler}>Prijavi se</Button>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  )
}