import React, {useContext, useEffect, useState} from "react";
import {AdminContext} from "../../contex/AdminContext";
import {Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader, Progress} from "reactstrap";
import {useDropzone} from "react-dropzone";
import {Check, UploadCloud, X} from "react-feather";

export default function ImageModal({modalData, setModalData, callbackHandler})
{
   const {AuthAPI, authErrorHandler} = useContext(AdminContext);
   const [hasError, setHasError] = useState(false);
   const [images, setImages] = useState([]);
   const imagePrefix = process.env.REACT_APP_API_URL + '/images/'

   const closeModal = () => {
      setModalData({show: false, team: null})
   }

   const {
      isDragActive,
      isDragAccept,
      isDragReject,
      getRootProps,
      getInputProps
   } = useDropzone({
      noKeyboard: true,
      multiple: false,
      onDrop: (acceptedFiles) => {
         acceptedFiles.map((file) => {

            setHasError(false);
            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            const data = new FormData();
            data.append("file", file);

            AuthAPI.post("/upload", data, config).then((res) => {
               setImages([{...res.data}, ...images]);
            }).catch((error) => {
               console.log('error', error.response.data);
               setHasError("Greška prilikom uploada datoteke");
               if(error.response && error.response.status === 401){
                  authErrorHandler();
               }
            })
         })
      }
   });

   const removeHandler = (id) => {
      AuthAPI.delete('/logo/' + id).then((res) => {
         const newImages = images.filter((item) => item.id !== id);
         setImages(newImages);
      }).catch((error) => {
         console.log('error', error);
         if(error.response && error.response.status === 401){
            authErrorHandler();
         }
      })
   }

   const loadData = () => {
      AuthAPI.get('/logo').then((res) => {
         setImages(res.data);
      }).catch((error) => {
         console.log('error', error);
         if(error.response && error.response.status === 401){
            authErrorHandler();
         }
      })
   }

   useEffect(() => {
      loadData();
   },  [])

   return (
      <>
         <Modal isOpen={modalData.show} size="lg">
            <ModalHeader toggle={() => {closeModal()}}>
               Odaberite logo ili prenesite novi
            </ModalHeader>
            <ModalBody>
               { hasError && (
                  <Alert color="danger">{ hasError }</Alert>
               )}
               <div className="dropzone">
                  <div
                     {...getRootProps({ className: 'dropzone-upload-wrapper' })}>
                     <input {...getInputProps()} />
                     <div className="dropzone-inner-wrapper dropzone-inner-wrapper-alt">
                        {isDragAccept && (
                           <div>
                              <div className="d-100 btn-icon mb-3 hover-scale-rounded bg-success shadow-success-sm rounded-lg text-white">
                                 <Check className="d-60" />
                              </div>
                              <div className="font-size-xs text-success">
                                 Ispustite za prijenos!
                              </div>
                           </div>
                        )}
                        {isDragReject && (
                           <div>
                              <div className="d-100 btn-icon mb-3 hover-scale-rounded bg-danger shadow-danger-sm rounded-lg text-white">
                                 <X className="d-60" />
                              </div>
                              <div className="font-size-xs text-danger">
                                 Format datoteke nije podržan
                              </div>
                           </div>
                        )}
                        {!isDragActive && (
                           <div>
                              <div className="d-90 btn-icon mb-3 hover-scale-rounded bg-white shadow-light-sm rounded-lg text-first">
                                 <UploadCloud className="d-60" />
                              </div>
                              <div className="font-size-sm">
                                 Kliknite ovdje ili dovucite datoteku za prijenos
                              </div>
                           </div>
                        )}
                     </div>
                  </div>
               </div>

               <div className="image-container">
                  { images.map((image) => (
                    <div className="item" key={image.id}>
                       <Button color="link" onClick={() => callbackHandler(image.fileName)} title={image.title}>
                          <img src={ imagePrefix + image.fileName } alt={image.title} />
                       </Button>
                       <Button color="link" className="btn-remove" onClick={() => removeHandler(image.id)}>
                          <X className="close-icon" />
                       </Button>
                    </div>
                  )) }
               </div>
            </ModalBody>
         </Modal>
      </>
   )
}