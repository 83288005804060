import React, { lazy, Suspense } from "react";
import Loading from "./components/Loading";
import { Routes ,Route } from 'react-router-dom';
import AdminLayout from "./layout-blueprints/AdminLayout";
import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import Settings from "./pages/Settings";
import AuthProvider from "./Providers/AuthProvider";
import MainLayout from "./layout-blueprints/MainLayout";
import Semafor from "./pages/Semafor";
import MainProvider from "./Providers/MainProvider";

const RoutesProvider = () => {

  const SuspenseLoading = () => {
    return <Loading />;
  };

  return (
    <Suspense fallback={<SuspenseLoading />}>
        <Routes>
          <Route element={<AdminLayout />} >
            <Route element={<AuthProvider />} >
              <Route path="/" element={<HomePage />} />
              <Route path="/settings" element={<Settings />} />
            </Route>
            <Route path="/Login" element={<LoginPage />} />
          </Route>

          <Route element={<MainLayout />}>
            <Route element={<MainProvider />}>
              <Route path="/semafor" element={<Semafor />} />
            </Route>
          </Route>
        </Routes>
    </Suspense>
  )
}

export default RoutesProvider;