import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

export default function ({setModalData, callbackHandler})
{
  const closeModal = () => {
    setModalData({show:false, itemID: null})
  }

  return (
    <Modal isOpen={true} size="md">
      <ModalHeader toggle={() => {closeModal()}}>
        Upozorenje
      </ModalHeader>
      <ModalBody>
        Jeste li sigurni da želite izbrisati gol?
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={closeModal}>Odustani</Button>
        <Button color="primary" onClick={callbackHandler}>Potvrdi</Button>
      </ModalFooter>
    </Modal>
  )
}